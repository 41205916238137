<template>
  <div class="richtext-container">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="160px"
      class="demo-ruleForm"
    >
      <el-form-item label="是否参与砍价:" prop="is_bargain">
        <el-switch
          v-model="form.is_bargain"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="商品名称:" prop="name">
        <!-- <el-input
          v-model="form.name"
          placeholder="请输入名称"
          style="width: 350px"
        ></el-input> -->
        {{ form.name }}
      </el-form-item>

      <el-form-item label="商品封面:" prop="cover">
        <!-- <upload-one
          v-model="form.cover"
          style="width: 350px; height: 150px"
        ></upload-one> -->
        <img style="width: 350px; height: 150px" :src="form.cover" alt="" />
      </el-form-item>

      <!-- <el-form-item label="价格" prop="price"> -->

        <!-- <el-input
          v-model="form.price"
          style="width: 350px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input> -->
        
        <!-- {{ form.price }}
      </el-form-item> -->
      <el-form-item label="砍价最低价（元）" prop="bargain_min_price">
        <el-input
          v-model="form.bargain_min_price"
          style="width: 350px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input>
      </el-form-item>
      <el-form-item label="每次砍价上限（元）" prop="every_bargain_max_money">
        <el-input
          v-model="form.every_bargain_max_money"
          style="width: 350px"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      id: null,
      btnLoading: false,
      form: {
        name: "",
        cover: "",
        price: "",
        is_bargain: 1,
        bargain_min_price: "",
        every_bargain_max_money: "",
      },
      rules: {
        name: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
        cover: [
          { required: true, message: "商品封面不能为空", trigger: "change" },
        ],
        price: [{ required: true, message: "价格不能为空", trigger: "blur" }],
        bargain_min_price: [
          { required: true, message: "砍价最低价不能为空", trigger: "blur" },
        ],
        every_bargain_max_money: [
          { required: true, message: "每次砍价上限不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    // this.form = JSON.parse(this.$route.query.data);
    // console.log(this.form,'============');
    this.id = this.$route.query.data;
    this.getDetail();
  },
  methods: {
    getDetail() {
      request({
        url: "/api/backend/product/detail",
        method: "get",
        params: {
          key: "park_config",
          id: this.id,
        },
      }).then((response) => {
        this.form = response.data;
        if (response.data.bargain_price == null) {
          this.form.is_bargain = 1;
        }
        this.$forceUpdate(); //强制更新
      });
    },
    saveData() {
        console.log(this.form.bargain_min_price);
        console.log(1111111111111111);
      if(this.form.bargain_min_price==0){
          return this.$message({
                type: "error",
                message: "最低价不能为空",
              });
      }
      
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/product/bargainStore",
            method: "post",
            data: this.form,
          })
            .then(() => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
}
</style>
